.center {
  border: 1px solid grey; }

.list-icon {
  border: white 1px solid; }

.ql-editor {
  white-space: normal !important;
  color: white; }

.yt-player {
  background: none;
  height: 0;
  margin-top: -100px; }

.writings {
  overflow: hidden;
  position: relative;
  width: 100%; }
  .writings .writings-background {
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    opacity: 0.7; }
  .writings .writings-content {
    overflow: auto;
    height: 100%;
    padding-top: 110px;
    position: absolute;
    transition: top 1s; }
  .writings .writing-detail {
    font-family: "Cormorant Garamond", serif;
    border-top: #c19a6b solid 2px;
    background-color: black;
    position: absolute;
    width: 100%;
    height: 92%;
    transition: top 1s; }
    .writings .writing-detail h1 {
      font-size: 54px;
      margin-bottom: 0px; }
    .writings .writing-detail .writing-detail-image {
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      opacity: 0.2; }
    .writings .writing-detail .writing-detail-container {
      overflow: -moz-scrollbars-none;
      overflow-y: auto;
      -ms-overflow-style: none;
      align-items: center;
      position: relative;
      display: flex;
      font-size: 20px;
      flex-direction: column;
      color: white; }
      .writings .writing-detail .writing-detail-container a {
        text-align: center;
      }
      .writings .writing-detail .writing-detail-container .content {
        font-size: 1.125rem;
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.4);
        box-shadow: 0 0 80px 80px rgba(0, 0, 0, 0) inset; }
      .writings .writing-detail .writing-detail-container .date {
        margin-bottom: 20px; }
      .writings .writing-detail .writing-detail-container .desc {
        margin-top: 10px;
        font-size: 24px;
        margin-bottom: 20px; }
      .writings .writing-detail .writing-detail-container .writing-detail-toparrow {
        transition: transform 1s;
        width: 15%;
        padding-bottom: 50px; }
        .writings .writing-detail .writing-detail-container .writing-detail-toparrow:hover {
          transform: scale(1.25); }
      .writings .writing-detail .writing-detail-container .writing-detail-underline {
        width: 40%;
        padding-bottom: 10px; }
      .writings .writing-detail .writing-detail-container .writing-detail-bottomsign {
        width: 35%; }
  .writings .closed {
    top: 100%; }
  .writings .open {
    top: 109px; }
  .writings .grid {
    min-height: 200px;
    margin-bottom: -250px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    .writings .grid .writing-item {
      text-decoration: none;
      transition: transform 1s;
      text-align: center;
      margin: 5px;
      color: white;
      font-size: 20px; }
      .writings .grid .writing-item .border-extra {
        border: 1px solid white; }
      .writings .grid .writing-item .date {
        font-size: 16px; }
      .writings .grid .writing-item .outer-center {
        padding-left: 5px;
        padding-right: 5px;
        border: 1px solid white; }
      .writings .grid .writing-item .writing-image-container {
        height: 70%; }
        .writings .grid .writing-item .writing-image-container img {
          height: 100%;
          width: 100%;
          object-fit: contain; }
      .writings .grid .writing-item:hover {
        transform: scale(1.05); }
      .writings .grid .writing-item .writing-lower {
        padding-bottom: 5px;
        background-color: rgba(0, 0, 0, 0.8);
        font-family: "Cormorant Garamond", serif; }
      .writings .grid .writing-item:hover {
        border: none; }
      .writings .grid .writing-item .date {
        color: #D3D3D3; }
      .writings .grid .writing-item .preview {
        color: grey; }
  .writings .search {
    color: #c19a6b;
    text-align: center;
    font-size: 24px;
    max-width: 400px;
    margin: auto;
    margin-bottom: 15px; }
    .writings .search ::placeholder {
      color: #a0a0a0; }
    .writings .search input {
      border: none;
      width: 100%;
      background-color: rgba(0, 0, 0, 0);
      color: white;
      border-radius: 0px;
      border-bottom: #c19a6b 1px solid;
      padding: 5px; }
      .writings .search input:focus {
        outline: none;
        border-bottom: white 1px solid; }

.adminContainer {
  text-align: center; }
  .adminContainer .imageData {
    width: 100%;
    text-align: center; }
    .adminContainer .imageData img {
      width: 400px;
      border: solid black 1px;
      margin-left: 5px;
      margin-right: 5px; }
  .adminContainer .upload {
    background-color: #FF9900; }
  .adminContainer .imageList {
    margin: auto;
    max-width: 1200px;
    overflow-x: auto;
    display: flex;
    flex-direction: row; }
    .adminContainer .imageList .imageListItem {
      width: 400px;
      text-align: left; }
      .adminContainer .imageList .imageListItem img {
        width: 100%; }
      .adminContainer .imageList .imageListItem button {
        width: 100%;
        background-color: red;
        border-radius: 0; }

.video {
  margin-bottom: 150px;
  max-width: 1400px;
  font-family: "Cormorant Garamond", serif;
  width: 100%;
  padding-top: 150px; }
  .video .desc-opener {
    background-color: black;
    border: none !important;
    color: grey;
    width: 100%;
    outline: 0 !important;
    cursor: pointer;
    padding-top: 10px; }
    .video .desc-opener:focus {
      outline: 0 !important; }
    .video .desc-opener:active {
      outline: 0 !important; }
  .video .desc-opener:focus {
    outline: 0; }
  .video .archer {
    top: 200px; }
  .video img {
    width: 100%; }
  .video .desc {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 20px;
    position: relative;
    text-align: center;
    color: white; }
    .video .desc h2 {
      font-size: 30px; }
    .video .desc h3 {
      padding-left: 15px;
      padding-right: 15px;
      font-size: 20px; }
  .video .player {
    width: 100%;
    margin-top: -200%; }
  .video .core {
    margin-bottom: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: stretch; }
    .video .core .outer-center {
      min-width: 70%;
      padding: 5px;
      border: 1px solid grey; }
    .video .core .mid-center {
      padding: 2px;
      border: 1px solid grey; }
    .video .core .slidebutton {
      max-width: 400px;
      min-width: 200px;
      outline: none !important;
      border: none;
      font-size: 50px;
      background: none;
      height: inherit;
      border-radius: 0px; }
      .video .core .slidebutton:focus {
        outline: none !important; }
      .video .core .slidebutton:active {
        outline: none !important; }
    .video .core .picture {
      display: flex;
      flex-direction: row;
      flex-grow: 2; }

.start-slides-background {
  width: 100%;
  height: 100%;
  position: absolute; }

.slides-player-parent {
  opacity: 0;
  transition: opacity 1s;
  position: relative;
  z-index: 40;
  width: 100%;
  height: 100%; }
  .slides-player-parent:hover {
    opacity: 0.4;
    transition: opacity 1s; }
  .slides-player-parent .slides-player {
    position: absolute;
    bottom: 0%;
    width: 100%;
    z-index: 40;
    background-color: black;
    display: flex;
    flex-direction: row;
    align-items: center; }
    .slides-player-parent .slides-player .slides-player-button {
      margin-left: 10px;
      margin-top: 0px;
      background: none;
      outline: none !important;
      border: none;
      color: white; }
    .slides-player-parent .slides-player .slides-fullscreen-button {
      margin-left: 20px;
      margin-top: 0px;
      background: none;
      border: none;
      outline: none !important;
      color: white; }
    .slides-player-parent .slides-player .slides-volume-control {
      margin-left: 40px;
      color: white;
      background: none; }
    .slides-player-parent .slides-player .slides-volume-control::-webkit-slider-thumb {
      -webkit-appearance: none;
      /* Override default look */
      appearance: none;
      width: 25px;
      /* Set a specific slider handle width */
      height: 25px;
      /* Slider handle height */
      background: #4CAF50;
      /* Green background */
      cursor: pointer;
      /* Cursor on hover */ }
    .slides-player-parent .slides-player .slides-volume-control::-moz-range-thumb {
      width: 25px;
      /* Set a specific slider handle width */
      height: 25px;
      /* Slider handle height */
      background: #4CAF50;
      /* Green background */
      cursor: pointer;
      /* Cursor on hover */ }

.start-slides-fadeaway {
  width: 100%;
  height: 100%;
  position: absolute; }

.start-outer-center {
  position: absolute;
  z-index: 20;
  left: calc(50% - 40px);
  top: calc(50% - 80px); }
  .start-outer-center:hover {
    transform: scale(1.05);
    transition: transform 1s; }

.start-preimage {
  width: 100%;
  height: 100%; }

.start-border-extra {
  border: white 1px solid;
  width: 100%;
  left: -4%;
  position: absolute;
  height: 110%;
  top: -5%;
  pointer-events: none; }

.start-slides-button {
  padding-top: 1px;
  padding-bottom: 1px;
  width: 105%;
  height: 110%;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 0px;
  font-size: 30px;
  margin-left: -5px;
  border: white solid 1px;
  outline: none !important;
  font-family: "Cormorant Garamond", serif; }
  .start-slides-button:hover {
    border: white solid 1px; }
  .start-slides-button:hover {
    background: #0a0a0a; }
  .start-slides-button:focus {
    outline: none; }

.custom-player {
  position: absolute;
  left: 0px;
  right: 0px;
  margin-left: 5px;
  margin-right: 5px;
  transform: translateY(-100%);
  color: white;
  background-color: rgba(0, 0, 0, 0.7); }
  .custom-player .custom-player-control {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row; }
  .custom-player .custom-player-btn {
    outline: none;
    width: 100px;
    color: white;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    padding: 15px;
    padding-right: 50px; }
  .custom-player .player-vol {
    padding-bottom: 15px; }
  .custom-player .player-progress-bar {
    height: 10px;
    background-color: rgba(0, 0, 0, 0.7); }
  .custom-player .inner-player-progress {
    height: 10px;
    background-color: white; }
  .custom-player .vol-controller {
    margin: auto;
    margin-top: 15px;
    display: block; }
  .custom-player .custom-player-timer {
    margin-right: 20px;
    min-width: 150px;
    margin-left: 20px;
    font-size: 25px;
    padding-bottom: 12px; }
  .custom-player .buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
    margin-right: 20px; }
    .custom-player .buttons button {
      font-size: 20px;
      color: white;
      background: none;
      border: none; }
    .custom-player .buttons .backward {
      transform: scaleX(-1); }

@keyframes reverse {
  from {
    transform: rotate(0.5turn) !important; }
  to {
    transform: rotate(0turn) !important; } }

.reverse span {
  animation-fill-mode: forwards;
  animation-name: reverse;
  animation-duration: 0.5s; }

.archer {
  position: relative;
  width: 270px !important;
  top: 40%;
  left: 10%;
  transform: rotate(-10deg); }

.flipped {
  left: -45%;
  transform: scaleX(-1) rotate(-10deg); }

.slides {
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(25, 25, 50, 0.59);
  -moz-box-shadow: 0px 10px 20px 0px rgba(25, 25, 50, 0.59);
  box-shadow: 0px 10px 20px 0px rgba(25, 25, 50, 0.59); }
  .slides > div {
    height: 100%; }

.stream {
  position: absolute; }

.App-header {
  z-index: 15;
  display: flex;
  position: absolute;
  flex-direction: row;
  border-bottom: #c19a6b solid 6px;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  font-size: 30px; }
  .App-header .soundtoggle {
    position: absolute;
    margin-left: auto;
    z-index: 10;
    right: 10px;
    top: 22px;
    color: #c19a6b; }
    .App-header .soundtoggle span {
      color: white;
      width: 0;
      height: 0;
      border-left: 18px solid transparent;
      border-right: 18px solid transparent;
      border-top: 30px solid #c19a6b;
      transform: rotate(0.5turn); }
  .App-header .fullscreen {
    position: absolute;
    margin-left: auto;
    z-index: 10;
    right: 50px;
    top: 22px;
    color: #c19a6b; }
    .App-header .fullscreen span {
      color: white;
      width: 0;
      height: 0;
      border-left: 18px solid transparent;
      border-right: 18px solid transparent;
      border-top: 30px solid #c19a6b;
      transform: rotate(0.5turn); }
  .App-header .home {
    border-top: solid 2px rgba(0, 0, 0, 0);
    border-bottom: solid 2px rgba(0, 0, 0, 0);
    font-family: "Cormorant Garamond", serif;
    font-size: 45px;
    color: #c19a6b !important; }
  .App-header .home:hover {
    padding: -40px !important;
    border-top: solid 2px 	#fffacd;
    border-bottom: solid 2px 	#fffacd;
    color: #fffacd !important; }
  .App-header .link {
    margin-top: 14px;
    font-size: 30px;
    border-top: solid 2px rgba(0, 0, 0, 0);
    border-bottom: solid 2px rgba(0, 0, 0, 0);
    font-family: "Cormorant Garamond", serif;
    color: #c19a6b !important; }
  .App-header .selected {
    border-top: solid 2px #c19a6b;
    border-bottom: solid 2px #c19a6b; }
  .App-header .link:hover {
    padding: -40px !important;
    border-top: solid 2px 	#fffacd;
    border-bottom: solid 2px 	#fffacd;
    color: #fffacd !important; }

.search {
  font-family: "Cormorant Garamond", serif; }

.error {
  font-family: "Cormorant Garamond", serif; }

.border-extra {
  box-sizing: content-box;
  position: absolute;
  top: -7px;
  left: 5px;
  padding: 8px 0;
  width: calc(100% - 10px);
  height: 99%;
  transform: translateZ(0);
  border: 1px solid grey; }

.error {
  color: white;
  font-size: 40px;
  text-align: center;
  width: 100%; }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes fade-out {
  0% {
    opacity: 0; }
  75% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-out {
  0% {
    opacity: 1; }
  75% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.fade-out {
  animation: fade-out 1s;
  position: relative;
  width: 100%;
  height: 100%; }

.fade-in {
  position: relative;
  top: 0px;
  width: 100%;
  animation: fade-in 1s; }

.loadingbg {
  height: 100%;
  width: 100%;
  background-color: black;
  text-align: center;
  position: absolute;
  vertical-align: middle; }
  .loadingbg > div {
    position: absolute;
    top: 45%;
    left: 50%;
    width: 100px;
    height: 100px;
    transform: translate(-50%, -50%); }
    .loadingbg > div svg {
      opacity: .5;
      color: grey;
      font-size: 100px;
      animation: spin 1.5s infinite linear; }

.subtitle {
  margin-top: 10px; }

.grid {
  margin: auto;
  min-height: 200px;
  margin-bottom: -250px;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap; }
  .grid .grid-item {
    overflow: hidden;
    text-decoration: none !important;
    transition: transform 1s;
    margin: 5px;
    color: white;
    font-size: 20px; }
    .grid .grid-item:hover {
      transform: scale(1.1); }
    .grid .grid-item .date {
      font-size: 16px;
      color: #D3D3D3; }

.video .search {
  color: #c19a6b;
  text-align: center;
  font-size: 24px;
  max-width: 400px;
  margin: auto;
  margin-bottom: 15px; }
  .video .search input {
    border: none;
    width: 100%;
    background-color: black;
    color: white;
    border-radius: 0px;
    border-bottom: #c19a6b 1px solid;
    padding: 5px; }
    .video .search input:focus {
      outline: none;
      border-bottom: white 1px solid; }

.nav {
  justify-content: center; }
  .nav .nav-item {
    margin-left: 15px;
    margin-right: 15px; }

.hide {
  display: none; }

@font-face {
  font-family: "Roman";
  src: url(./assets/fonts/CALID___.TTF) format("truetype"); }

.header-title {
  padding-top: 100px;
  font-size: 60px;
  font-family: "Roman";
  color: #fffacd;
  text-align: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #fffacd; }

.lower .sub-title {
  z-index: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  color: white;
  font-family: "Cormorant Garamond", serif;
  z-index: 20;
  position: relative; }
  .lower .sub-title h1 {
    font-size: 60px; }
  .lower .sub-title .left-sword {
    z-index: 0;
    right: 0px;
    transition: right 0.5s;
    position: relative;
    top: -15px;
    transform: scale(-0.3, 0.3); }
  .lower .sub-title .right-sword {
    left: 0px;
    transition: left 0.5s;
    position: relative;
    top: -15px;
    transform: scale(0.3, 0.3); }

.lower .about {
  position: relative;
  color: white; }
  .lower .about .sub-title {
    top: 206px; }
  .lower .about .about-parallax {
    height: auto;
    margin-top: -20px;
    opacity: 0.6; }
  .lower .about .back-badge {
    top: 130%;
    width: 500px;
    opacity: 0.2;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0); }
  .lower .about .left-image {
    top: 220px;
    opacity: 0.7;
    left: 2%;
    position: absolute;
    transform: scaleX(-1); }
  .lower .about .right-image {
    top: 240px;
    opacity: 0.7;
    position: absolute;
    right: 0;
    z-index: 2; }
  .lower .about .top-image {
    left: 50%;
    position: relative;
    z-index: 0;
    opacity: 0.9;
    max-width: 750px;
    transform: translate(-50%, 0); }
  .lower .about .container {
    position: relative;
    z-index: 4;
    max-width: 1500px; }
  .lower .about .content {
    padding-left: 170px;
    padding-right: 170px;
    margin-bottom: 50px;
    margin-top: -150px; }
    .lower .about .content p {
      z-index: 5;
      margin-top: 20px;
      font-size: 20px;
      font-family: "Cormorant Garamond", serif;
      text-align: center; }

.lower .support {
  background: none;
  margin-bottom: -20px;
  position: relative;
  color: white;
  font-family: "Cormorant Garamond", serif;
  font-size: 20px; }
  .lower .support .alexcoin {
    opacity: 0.7;
    width: 25%;
    position: absolute;
    left: 20px; }
  .lower .support .selecoin {
    opacity: 0.7;
    width: 25%;
    position: absolute;
    right: 20px; }
  .lower .support .bowl {
    opacity: 0.7;
    width: 30%; }
  .lower .support .sub-title:hover .left-sword {
    right: 50px;
    transition: right 1s; }
  .lower .support .sub-title:hover .right-sword {
    left: 50px;
    transition: left 1s; }
  .lower .support .open .left-sword {
    right: 200px;
    transition: right 1s; }
  .lower .support .open .right-sword {
    left: 200px;
    transition: left 1s; }
  .lower .support .ashalink {
    color: white; }
  .lower .support .collapes {
    background: none; }
  .lower .support .support-content {
    background: none;
    text-align: center; }
    .lower .support .support-content .cols {
      display: flex;
      justify-content: center;
      flex-direction: row; }
      .lower .support .support-content .cols > div {
        width: 200px;
        text-align: left; }
      .lower .support .support-content .cols .right {
        margin-right: 80px; }

.app {
  background-color: black; }

html {
  overflow-x: hidden;
  overflow-y: visible;
  background-color: black; }

@keyframes darken {
  from {
    background-color: #313d5c; }
  to {
    background-color: #27314a; } }

.writing-preview {
  margin-top: 40px;
  color: #c19a6b;
  font-family: 'Montserrat', sans-serif;
  text-align: left; }
  .writing-preview .date {
    text-align: left;
    font-size: 16px; }
  .writing-preview .writing-mid {
    border: solid rgba(0, 0, 0, 0.4) 1px; }
  .writing-preview:hover .writing-lower {
    background-color: #27314a;
    transition: background-color 0.3s;
    color: #c19a6b; }
  .writing-preview .writing-lower {
    position: relative;
    padding: 4%;
    padding-bottom: 2%;
    padding-top: 3%;
    transition: background-color 0.3s;
    background-color: #313d5c;
    font-family: 'Nunito Sans', sans-serif; }
    .writing-preview .writing-lower h1 {
      font-family: 'Montserrat', sans-serif; }
    .writing-preview .writing-lower p {
      font-size: 16px;
      padding-bottom: 25px;
      border-bottom: 1px solid #c19a6b; }
  .writing-preview img {
    width: 100%; }

.nav {
  background: none !important; }
  .nav span {
    background: url("./assets/slidearrow.png") !important;
    border: none !important;
    height: 400px !important;
    width: 400px;
    transform: none !important; }

.smoke {
  max-width: 100%;
  height: auto;
  width: 100%;
  position: relative;
  z-index: 10;
  -webkit-user-select: none;
  /* Chrome/Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */
  /* Rules below not implemented in browsers yet */
  -o-user-select: none;
  user-select: none; }

h3 {
  text-align: center; }

.players {
  height: 0px;
  width: 100%; }

.support-content p {
  margin-bottom: 0px; }

.podcastList {
  margin: auto;
  display: flex;
  flex-direction: row;
  max-width: 800px;
  overflow: auto; }
  .podcastList .podcastItem {
    display: flex;
    flex-direction: column;
    max-width: 400px; }
    .podcastList .podcastItem h4 {
      font-size: 10px;
      max-height: 230px;
      overflow: hidden; }
    .podcastList .podcastItem button {
      border-radius: 0; }
    .podcastList .podcastItem .edit {
      background-color: blue; }
    .podcastList .podcastItem .delete {
      background-color: red; }
  .podcastList img {
    max-width: 400px; }

.fullscreenButton {
  position: absolute;
  z-index: 10;
  border-radius: 0;
  right: 1%;
  bottom: 50%;
  color: #c19a6b;
  outline: none !important;
  background-color: rgba(0, 0, 0, 0);
  transform: translateY(50%);
  font-size: 25px;
  padding: 0px;
  padding-left: 6px;
  padding-right: 6px; }

.edited {
  margin: auto;
  max-width: 800px; }
  .edited textarea {
    width: 100%; }
  .edited img {
    max-width: 800px; }

.no-underline {
  text-decoration: none !important; }

@media screen and (max-width: 600px) {
  .video #grid {
    width: 100%; }
  #front-content {
    padding-left: 0px;
    padding-right: 0px; }
  .left-image {
    opacity: 0.7; }
  .writing-item {
    width: 99%;
    padding: 1%; }
  .fullscreen {
    right: -8% !important;
    top: 15px !important; }
  .soundtoggle {
    right: 0% !important;
    top: 15px !important; }
  #left-image {
    top: 80%;
    width: 200px; }
  #back-badge {
    top: 180%; }
  #top-image {
    margin-top: 10%; }
  #right-image {
    top: 90%;
    width: 230px; }
  .nav-item {
    margin-left: 1px;
    margin-right: 1px; }
  #header-nav {
    position: relative;
    z-index: 15; }
  .slidebutton {
    overflow: hidden;
    min-width: 0px !important; }
  .archer {
    display: none; }
  .link {
    font-size: 18px !important;
    margin-top: 4px !important; }
  .home {
    font-size: 18px !important;
    margin-top: 4px !important; }
  .home {
    margin-top: 14px; }
  .fullscreen {
    right: -8% !important; }
  .soundtoggle {
    right: 0% !important; } }

h4 {
  color: white; }

@media screen and (max-width: 500px) {
  .smoke {
    top: 70px; }
  .open {
    top: 11% !important; }
  .player-vol {
    margin-right: 20px !important;
    margin-left: -10px !important; }
  #front-content p {
    font-size: 15px;
    margin-top: 0px;
    margin-bottom: 5px;
    font-size: 1.125rem; }
  #front-support {
    font-size: 1.125rem; }
    #front-support #left-sword {
      display: none; }
    #front-support #right-sword {
      display: none; }
  #alex, #sele {
    top: 20%; }
  #front-subtitle h1 {
    margin-top: 15px;
    font-size: 30px; }
  #front-subtitle #left-sword {
    right: -220px;
    width: 120px;
    top: 0px;
    transition: none;
    height: 40px;
    transform: none;
    margin-top: 10px; }
  #front-subtitle #right-sword {
    left: -220px;
    width: 120px;
    top: 0px;
    transition: none;
    height: 40px;
    transform: scaleX(-1);
    margin-top: 10px; }
  .custom-player-timer {
    display: none !important; }
  #header-nav {
    margin-left: 1px;
    margin-right: 1px; } }

@media screen and (min-width: 601px) and (max-width: 768px) {
  .video #grid {
    width: 100%; }
  #player-title {
    font-size: 20px; }
  #front-content {
    padding-left: 0px;
    padding-right: 0px; }
  .left-image {
    opacity: 0.7; }
  .open {
    top: 13% !important; }
  .writing-item {
    width: 99%;
    padding: 1%; }
  .fullscreen {
    right: -8% !important;
    top: 15px !important; }
  .soundtoggle {
    right: 0% !important;
    top: 15px !important; }
  #left-image {
    top: 80%;
    width: 200px; }
  #back-badge {
    top: 180%; }
  #top-image {
    margin-top: 10%; }
  #right-image {
    top: 90%;
    width: 230px; }
  #nav-item {
    margin-left: 2px;
    margin-right: 2px;
    font-size: 20px; }
  .home {
    font-size: 30px !important;
    margin-top: 14px !important; }
  #header-nav {
    position: relative;
    z-index: 15; }
  .left-sword {
    right: -190px !important;
    transition: right 1s !important; }
  .right-sword {
    left: -190px !important;
    transition: left 1s !important; }
  .alexcoin, .selecoin {
    width: 15% !important;
    top: 60% !important; }
  .slidebutton {
    overflow: hidden;
    min-width: 0px !important; }
  .archer {
    display: none; } }

@media screen and (min-width: 769px) and (max-width: 992px) {
  #player-title {
    font-size: 30px; }
  .fullscreen {
    right: 1% !important; }
  .soundtoggle {
    right: 6% !important; }
  .grid-item {
    width: 47.9%; }
  .writing-item {
    width: 48%;
    padding: 1%; }
  .slidebutton {
    min-width: 0px !important; }
  .archer {
    display: none; }
  #left-image {
    top: 50%;
    width: 200px; }
  #right-image {
    top: 60%;
    width: 230px; }
  .alexcoin {
    margin-top: 200px;
    width: 20% !important; }
  .selecoin {
    margin-top: 200px;
    width: 20% !important; }
  #header-nav {
    margin-left: 0px;
    margin-right: 0px; }
  .left-sword {
    right: -190px !important;
    transition: right 1s !important; }
  .right-sword {
    left: -190px !important;
    transition: left 1s !important; } }

@media screen and (min-width: 993px) and (max-width: 1200px) {
  #player-title {
    font-size: 16px;
    padding-bottom: 0px; }
  .grid-item {
    width: 31.4%; }
  .writing-item {
    width: 32%;
    padding: 1%; }
  #left-image {
    top: 50%;
    width: 200px; }
  #right-image {
    top: 60%;
    width: 230px; }
  .alexcoin {
    margin-top: 30px;
    width: 20% !important; }
  .selecoin {
    margin-top: 30px;
    width: 20% !important; }
  .fullscreen {
    right: 2% !important; }
  .soundtoggle {
    right: 7% !important; }
  #header-nav {
    margin-left: 0px;
    margin-right: 0px; }
  .left-sword {
    right: -170px !important;
    transition: right 1s !important; }
  .right-sword {
    left: -170px !important;
    transition: left 1s !important; }
  .sub-title:hover .left-sword {
    right: 0px !important;
    transition: right 1s; }
  .sub-title:hover .right-sword {
    left: 0px !important;
    transition: left 1s; }
  .open .left-sword {
    z-index: 0;
    right: 20px !important;
    transition: right 1s; }
  .open .right-sword {
    left: 20px !important;
    transition: left 1s; } }

@media screen and (min-width: 1201px) {
  .fullscreen {
    right: -4% !important; }
  .soundtoggle {
    right: 1% !important; }
  #player-title {
    padding-top: 12px;
    font-size: 30px; }
  #header-nav {
    margin-left: 57px;
    margin-right: 57px; }
  .writing-item {
    width: 32%;
    padding: 1%; }
  .grid-item {
    width: 19%; }
  .alexcoin {
    margin-top: 30px;
    width: 25% !important; }
  .selecoin {
    margin-top: 30px;
    width: 25% !important; }
  #left-image {
    top: 40%;
    width: 280px; }
  #right-image {
    top: 50%;
    width: 340px; } }

@media screen and (min-width: 1401px) {
  #player-title {
    padding-top: 12px;
    font-size: 30px; }
  #left-image {
    top: 40%;
    width: 330px; }
  #right-image {
    top: 50%;
    width: 390px; } }

@media screen and (min-width: 1680px) {
  #left-image {
    top: 40%;
    width: 390px; }
  #right-image {
    top: 50%;
    width: 450px; }
  .writing-item {
    width: 24%;
    padding: 1%; } }

@media screen and (min-width: 1800px) {
  #left-image {
    top: 40%;
    width: 450px; }
  #right-image {
    top: 50%;
    width: 560px; } }

@media screen and (min-width: 2500px) {
  #left-image {
    top: 40%;
    width: 700px; }
  #right-image {
    top: 50%;
    width: 800px; } }

.nav {
  flex-wrap: nowrap; }

.react-slideshow-fade-images-wrap > div {
  height: 100%; }

.slide-container {
  width: 70%;
  margin: auto; }

h3 {
  text-align: center; }

.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 300px; }

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center; }

.slideshow {
  display: flex;
  flex-direction: row; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadingIn {
  -webkit-animation: fadein 1.5s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1.5s;
  /* Firefox < 16 */
  -ms-animation: fadein 1.5s;
  /* Internet Explorer */
  -o-animation: fadein 1.5s;
  /* Opera < 12.1 */
  animation: fadein 1.5s; }

.fadingOut {
  opacity: 0;
  transition: opacity 1.5s; }

.each-fade {
  position: relative;
  display: flex; }

.each-fade .image-container {
  width: 100%;
  opacity: 0.7;
  overflow: hidden; }

.video .border-extra {
  left: 2px;
  width: calc(100% - 6px); }

.each-fade .image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.each-fade h2 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed; }
